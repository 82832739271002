<template>
  <main id="content" class="content_course content_practicusinternsive">
	  <div class="alignment">
      
      <div class="logotipo">
        <img src="@/assets/images/logo_c_practicus_intensive.png" alt="Practicus Intensive" border="0" />
      </div>

      <div class="description richeditor">
        <p>
          Após análise de <strong>MILHARES</strong> de recursos, sabemos exatamente quais<br />
          são os erros mais comuns dos candidatos na Prova Prática, e com<br />
          esse conhecimento criamos o curso <strong>PRACTICUS INTENSIVE!</strong>
        </p>
      </div>

      <section>
        <h1>Sobre o curso</h1>

        <div class="description richeditor">
          <p>
            Criamos um curso totalmente focado na Prova Prática, onde associamos o conhecimento<br />
            teórico a técnica de resolução de prova a fim de alcançar a tão sonhada aprovação!
          </p>
        </div>
      </section>

      <section>
        <div class="features">
          <!-- <div>
            <h1>Conteúdo</h1>

            <div class="description richeditor">
              <ul>
                <li>+ de 80 aulas;</li>
                <li>Aulas teóricas aplicadas à Prática;</li>
                <li>Estações Simuladas;</li>
                <li>Correção das estações simuladas;</li>
                <li>Aula "O Segredo da Prova Prática".</li>
              </ul>
            </div>
          </div> -->

          <div>
            <h1>Módulos</h1>

            <div class="description richeditor">
              <p>
                Serão <strong>6 semanas</strong> de curso, sendo cada<br />
                semana focada em uma grande área:
              </p>
    
              <ul>
                <li>Cirurgia Geral;</li>
                <li>Clínica Médica;</li>
                <li>Ginecologia e Obstetrícia;</li>
                <li>Pediatria;</li>
                <li>Preventiva;</li>
                <li>Radiologia e Procedimentos</li>
              </ul>
            </div>
          </div>
        </div>
      </section>



      <div class="logotipo" style="margin-top:100px;">
        <img src="@/assets/images/logo_c_practicus_intensive_premium.png" alt="Practicus Intensive Premium" border="0" />
      </div>

      <section>
        <div class="features">
          <div>
            <h1>Conteúdo</h1>

            <div class="description richeditor">
              <ul>
                <li>Aula Destrinchando o checklist;</li>
                <li>Mentoria Coletiva aos finais de semana (Bônus);</li>
                <li>Grupo de Whatsapp para dúvidas (Bônus);</li>
                <li>+100 temas;</li>
                <li>Estações simuladas online;</li>
                <li>Apostila com os checklists + resumos direcionados;</li>
                <li>Aula “O SEGREDO DA PROVA PRÁTICA”;</li>
                <li>NOVIDADE: Acesso ao MEDTASK Treinamento até o
                dia da prova prática (a depender do edital);</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="course_list">
          <ul>
            <li>
              <div class="image">
                <img src="@/assets/images/logo_c_practicus_intensive_premium.png" alt="" border="0" />
              </div>
<!--
              <div class="year">2023.2</div>
      
              <div class="features">
                <ul>
                  <li>Acesso ilimitado até o dia da prova segunda fase <strong>Revalida 2023.2</strong></li>
                  <li><i class="far fa-calendar-alt"></i> Início: <span>09/10/2023</span></li>
                  <li><i class="far fa-clock"></i> Duração: <span>6 semanas</span></li>
                </ul>
              </div>

              <div class="price">
                <div class="price_por">
                  R$
                  <strong>4.238</strong>
                  <span>,00</span>
                </div>
              </div>
      -->
              <a href="https://wa.me/5511932912099" target="_blank" class="global_cta">Compre aqui</a>
            </li>
          </ul>
        </div>
      </section>
      
      <section>
        <h1>Mentoria Coletiva<br />Practicus Intensive Premium 2023.2</h1>

        <div class="description richeditor">
          <ul>
            <li><strong>Início:</strong> 08/10/2023</li>
            <li><strong>Duração:</strong> 8 semanas</li>
            <li>Mentoria Coletiva aos finais de semana.</li>
          </ul>
        </div>
      </section>



      <div class="logotipo" style="margin-top:100px;">
        <img src="@/assets/images/logo_c_practicus_intensive_online.png" alt="Practicus Intensive Online" border="0" />
      </div>

      <section>
        <div class="features">
          <div>
            <h1>Conteúdo</h1>

            <div class="description richeditor">
              <ul>
                <li>Aula DESTRINCHANDO O CHECKLIST;</li>
                <li>+100 temas;</li>
                <li>Estações simuladas online;</li>
                <li>Apostila com os checklists + resumos direcionados;</li>
                <li>Aula “O SEGREDO DA PROVA PRÁTICA”.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="course_list">
          <ul>
            <li>
              <div class="image">
                <img src="@/assets/images/logo_c_practicus_intensive_online.png" alt="" border="0" />
              </div>
    <!--
              <div class="year">2023.2</div>
      
              <div class="features">
                <ul>
                  <li>Acesso ilimitado até o dia da prova segunda fase <strong>Revalida 2023.2</strong></li>
                  <li><i class="far fa-calendar-alt"></i> Início: <span>09/10/2023</span></li>
                  <li><i class="far fa-clock"></i> Duração: <span>6 semanas</span></li>
                </ul>
              </div>

              <div class="price">
                <div class="price_por">
                  R$
                  <strong>3.238</strong>
                  <span>,00</span>
                </div>
              </div>
      -->
              <a href="https://wa.me/5511932912099" target="_blank" class="global_cta">Compre aqui</a>
            </li>
          </ul>
        </div>
      </section>

      <section>
        <h1>Metodologia</h1>

        <div class="method_list">
          <ul>
            <li>
              <h2 class="title">Videoaulas</h2>
              <p class="description">
                Aulas com didática única e mnemônicos exclusivos
              </p>
            </li>
            <li>
              <h2 class="title">Plataforma</h2>
              <p class="description">
                Área restrita do aluno
              </p>
            </li>
            <li>
              <h2 class="title">Lives Mundo Revalida</h2>
              <p class="description">
                Lives no Youtube e Instagram focadas na prova prática
              </p>
            </li>
          </ul>
        </div>
      </section>



      <div class="logotipo" style="margin-top:100px;">
        <img src="@/assets/images/logo_c_premonicoes_practicus.png" alt="Premonições Practicus" border="0" />
      </div>

      <section>
        <div class="features">
          <div>
            <h1>Conteúdo</h1>

            <div class="description richeditor">
              <ul>
                <li>Revisão Intensiva pré-prova</li>
                <li>Temas quentes;</li>
                <!-- <li>Aula Destrinchando o Checklist;</li> -->
                <li>APOSTAS Mundo Revalida;</li>
                <!-- <li>Encerramento: "O Segredo da Prova Prática";</li> -->
              </ul>
            </div>
          </div>

          <div>
            <h1>Cronograma</h1>

            

            <div class="price">
          <!--    <div class="price_de" style="text-decoration: line-through; font-size: 18px;">
                <span>de:</span>
                <span>R$ 1500,00</span>
              </div> -->

              
            </div>

            <a href="https://wa.me/5511932912099" target="_blank" class="global_cta" style="margin:35px auto 0;">Compre aqui</a>
          </div>
        </div>
      </section>

      <section>
        <h1>Practicus Presencial</h1>
        <router-link :to="{ name: 'practicusPresencialCourse', hash: '#topo' }" class="global_cta global_cta_center" style="margin:25px auto 0;">Saiba mais</router-link>
      </section>



      <section>
        <h1>Aula gratuita</h1>

        <div class="description richeditor" style="width:100%;">
          <p>Nossas aulas são repletas de mnemônicos exclusivos para <strong>Prova Prática.</strong></p>

          <div class="richeditor_video">
            <figure>
              <div class="global_embed">
                <div class="global_embed_video">
                  <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/VITHRAt0CvE" title="ATLS - PRACTICUS INTENSIVE - PROFESSOR VINICIUS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
            </figure>
          </div>
        </div>
      </section>

      <section>
        <section class="component_testimonial">
          <a name="testimonials" />
          <h1>Depoimentos</h1>
          <h2>Venha fazer parte dos milhares de aprovados!</h2>

          <div class="swiper_carousel component_testimonial_carousel">
            <swiper
              :modules='modules'
              :loop='true'
              :pagination="{
                clickable: true,
                dynamicBullets: true,
                dynamicMainBullets: 3
              }"
              :breakpoints="{
                0: {
                  slidesPerView: 1,
                  slidesPerGroup: 1
                },
                1200: {
                  slidesPerView: 4,
                  slidesPerGroup: 4
                }
              }"
            >
              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Fiquei muito surpresa com a qualidade dos recursos e competência da equipe envolvida, que conseguiram 9 pontos. Esse ano quando me inscrevi para uma nova oportunidade, não tive menor dúvida do curso preparatório a escolher, SIM, mais uma vez confiei no trabalho deles. Que incansáveis durante todo o curso, estiveram à nossa disposição, sempre com muita vontade e parceria…. Foram 1000!<br />
                      Espero não precisar fazer mais uma prova, mas se for necessário, certeza que MUNDO REVALIDA é a minha escolha.<br />
                      SÓ AGRADECER
                    </p>
      
                    <div class="name">Inglid Lima Queiroz</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      O curso foi perfeito, o que mais me cativou foi o fato de vocês serem tão próximos de nós, as aulas foram perfeitas e muito didáticas, as mentorias também, não eram aulas mecânicas como vemos em outros preparatórios, sentimos o carinho de vocês e a proximidade, além de ter um valor acessível...independente do resultado da prova eu quero agradecê-los por tudo e com certeza indico o curso de olhos fechados... Muito obrigado
                    </p>

                    <div class="name">Rodrigo Fabiano de O. Neves</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Só tenho palavras de agradecimento a toda a equipe de Mundo Revalida pelo apoio e ajuda prestada para a realização desta segunda etapa da prova Revalida, professores competentes, de uma alta qualidade, para vocês só tenho palavras de GRATIDÃO. PARABÉNS PARA TODOS.
                    </p>

                    <div class="name">Lazaro Rubem Garcia</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Gostei muito do Practicus Intensive!<br />
                      Pia, Juan, Marcus Vinicius.  SÂO FANTÁSTICOS, SEM PALAVRAS,  para elogiar.
                      Prof. Fabio Nogueira deu um show na preventiva.
                      Hoje só me lembrava dele na escala de Fergstrom !!!
                      Professora Bia da pediatria, MARAVILHOSA !!
                      MUITO DEDICADA, AULAS PERFEITAS !!
                      Muita agradecida a toda equipe do Mundo Revalida !!

                    </p>

                    <div class="name">Carol</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Quero agradecer a Mundo Revalida pela mentoria recebida foi muito boa realmente ajudou na hora da realização da prova bem direcionado para a gente saber lidar, não travar e lutar até o último apito. <br /><br />
                      Caíram quase todos os temas que vocês ensinaram! Obrigado !!!
                    </p>

                    <div class="name">Francisco Chilo</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Muito, mas muito obrigado Mundo Revalida, vocês fizeram a diferença.<br /><br />
                      Lembrava de todos vocês nas minhas estações.<br /><br />
                      Sem vocês acho que ia ser impossível abordar os temas das estações.<br /><br />
                      O melhor curso prático do mundo
                    </p>

                    <div class="name">Juan Carlos Cuellar</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Eu amei o curso!!! Nem tenho palavras para agradecer a existência desse curso com esses professores excelentes.<br /><br />
                      Muito obrigado de coração aos professores desse curso maravilhoso!!
                    </p>

                    <div class="name">Raniele Cristina Rodrigues R. de Oliveira</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Só agradecer!!! Só gratidão!!!!<br /><br />
                      Tenham certeza que super super indicarei o curso para os colegas
                    </p>

                    <div class="name">Cristiana Maria da Conceição</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Gostaria de agradecer ao Mundo Revalida por todo o suporte em aulas e treinos da prova prática.  A palavra resume gratidão.
                    </p>

                    <div class="name">Cecilia Maria Cabral Nunes</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      O curso foi excelente, muito bem elaborado, tanto que a maioria dos temas caíram na prova. Só tenho a agradecer pelo apoio, foi de grande importância esse curso para mim. Valeuu equipe Mundo Revalida.<br /><br />
                      Vocês são Ferassss
                    </p>

                    <div class="name">Kleyton Ribeiro de Araújo</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Queria agradecer muiiito a todos os professores!! As aulas foram exatamente o que eu precisava. Não eram longas e as informações eram precisas!!! 
                      Com certeza vou indicar para todos que conheço!!!
                    </p>

                    <div class="name">Bianca Frez</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Estou muito agradecido com a equipe mundo revalida.  Vocês estão de parabéns.  Aceitaram quase todas as estações.  Foi uma ótima experiência.  Já estou indicando seu curso.
                    </p>

                    <div class="name">Ernesto Valdes Gordillo</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      A toda a equipe do Mundo Revalida! O meu muito obrigaaaaadaaaaaa!! Nossa não tenho palavras, só gratidão! Deus abençoe cada um de vocês.<br /><br />
                      Curso de excelência! Só gratidão ao Mundo Revalida!
                    </p>

                    <div class="name">Roberta Simões Lopes</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Agradeço a Mundo Revalida, muito boas as aulas e me prepararam muito bem.
                    </p>

                    <div class="name">Paula Requena Paz</div>
                  </div>
                </div>
              </swiper-slide>

            </swiper>
          </div>
        </section>
      </section>

    </div>
  </main>    
</template>

<script>

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
  name: "DiscursiveMaster",

  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },

  mounted() {
    this.$nextTick(() => {
        const el = document.querySelector('#topo');
        el && el.scrollIntoView();
    })
  },

  computed: {
    practicusIntensive2022_2() {
      return `https://pay.herospark.com/practicus-intensive-2022-2-80174`;
    },
    
    practicusIntensive2023_1() {
      return `https://wa.me/5511914297094`;
    },

    practicusIntensive2023_2() {
      return `https://wa.me/5511914297094`;
    },
    
    practicusIntensivePremium2023_1() {
      return `https://pay.herospark.com/practicus-premium-2023-2-172643`;
    },
    
    practicusIntensiveOnline2023_1() {
      return `https://pay.herospark.com/practicus-intensive-2023-2-on-line-201517`;
    },

    premonicoesPracticus() {
      return `https://pay.herospark.com/premonicoes-practicus-2023-2-site-196049`;
    }
  },
};
</script>

<style scoped lang="scss">

</style>

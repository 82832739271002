<template>
	<main id="content" class="content_terms_use">
		<div class="alignment">

      <h1>Termos de Uso</h1>

<h1>Termos de Uso da Mundo Revalida</h1>

<p>Bem-vindo ao site da <strong>Mundo Revalida</strong>. Ao acessar e utilizar nossos serviços, você concorda com os seguintes termos e condições. Leia atentamente antes de prosseguir.</p>

<hr>
<br>
<h2>1. Aceitação dos Termos</h2>

<p>Ao utilizar nosso site e serviços, você concorda em cumprir estes Termos de Uso e todas as leis e regulamentos aplicáveis. Se você não concordar com algum termo, não deverá utilizar nossos serviços.</p>

<hr>
<br>
<h2>2. Serviços Oferecidos</h2>

<p>A Mundo Revalida oferece cursos e treinamentos preparatórios para provas de medicina, incluindo o Revalida. Também disponibilizamos um sistema de banco de dados para uso exclusivo dos alunos matriculados.</p>

<hr>
<br>
<h2>3. Cadastro de Usuário</h2>

<p>Para acessar determinados serviços, é necessário realizar um cadastro, fornecendo informações precisas e atualizadas. Você é responsável por manter a confidencialidade de suas credenciais de acesso.</p>

<hr>
<br>
<h2>4. Uso Adequado dos Serviços</h2>

<p>Você concorda em utilizar nossos serviços apenas para fins legais e de acordo com estes Termos de Uso. É proibido:</p>

<ul>
    <li>Violar direitos de propriedade intelectual;</li>
    <li>Transmitir vírus ou qualquer código de natureza destrutiva;</li>
    <li>Interferir na segurança ou funcionamento do site;</li>
    <li>Realizar atividades fraudulentas ou ilícitas.</li>
</ul>

<hr>
<br>
<h2>5. Propriedade Intelectual</h2>

<p>Todo o conteúdo disponível no site, incluindo textos, imagens, gráficos e materiais didáticos, é de propriedade exclusiva da Mundo Revalida ou de seus licenciadores e é protegido por leis de direitos autorais e propriedade intelectual.</p>

<hr>
<br>
<h2>6. Limitação de Responsabilidade</h2>

<p>A Mundo Revalida não se responsabiliza por danos diretos ou indiretos resultantes do uso ou impossibilidade de uso dos serviços. Não garantimos que o conteúdo seja preciso, completo ou atualizado.</p>

<hr>
<br>
<h2>7. Modificações nos Serviços e Termos</h2>

<p>Reservamo-nos o direito de modificar ou descontinuar os serviços, temporária ou permanentemente, com ou sem aviso prévio. Também podemos alterar estes Termos de Uso a qualquer momento, sendo responsabilidade do usuário verificá-los periodicamente.</p>

<hr>
<br>
<h2>8. Links para Terceiros</h2>

<p>Nosso site pode conter links para sites de terceiros. Não temos controle sobre o conteúdo ou práticas desses sites e não assumimos responsabilidade por eles.</p>

<hr>
<br>
<h2>9. Política de Privacidade</h2>

<p>O uso de nossos serviços também é regido por nossa <a href="politica-de-privacidade.html">Política de Privacidade</a>, que está incorporada a estes Termos de Uso.</p>

<hr>
<br>
<h2>10. Lei Aplicável e Jurisdição</h2>

<p>Estes Termos de Uso são regidos pelas leis da República Federativa do Brasil. Qualquer disputa será submetida ao foro da comarca de São Paulo, Estado de São Paulo, com exclusão de qualquer outro, por mais privilegiado que seja.</p>

<hr>
<br>
<h2>11. Contato</h2>

<p>Para dúvidas ou esclarecimentos sobre estes Termos de Uso, entre em contato conosco:</p>

<ul>
    <li><strong>E-mail:</strong> <a href="mailto:educacao@mundorevalida.com.br">educacao@mundorevalida.com.br</a></li>
    <li><strong>Endereço:</strong> Rua Exemplo, 123, Bairro, Cidade, Estado, CEP</li>
</ul>

<hr>
<br>
<h2>12. Disposições Gerais</h2>

<p>Se alguma disposição destes Termos de Uso for considerada inválida ou inexequível, as demais disposições permanecerão em pleno vigor e efeito.</p>


    </div>
  </main>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'TermsOfUseView',
  components: {
    
  },

  mounted() {
    
  }
}
</script>

<style scoped lang="scss">

</style>
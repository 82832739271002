<template>
  <section class="component_about">
    <h1>Mundo revalida</h1>
    <h2>Revolucionando o ensino do <strong>Revalida</strong></h2>

    <div class="wrapper">
      <div class="description">
        O <strong>Mundo Revalida</strong> surge com o objetivo de guiar a sua trajetória até a aprovação.  Somos pioneiros e especialistas na elaboração de recursos e com todo esse conhecimento oferecemos cursos com uma didática inédita.

        <a href="/#courses" class="global_cta">Conheça nossos cursos</a>
      </div>
      
      <ul>
        <li>
          <div class="icon"><i class="fas fa-star"></i></div>
          <p class="title">Didática<br /> Única</p>
        </li>
        <li>
          <div class="icon"><i class="fas fa-users"></i></div>
          <p class="title">Professores<br /> de Elite</p>
        </li>
        <li>
          <div class="icon"><i class="fas fa-medal"></i></div>
          <p class="title">Pioneiros<br /> em Recursos</p>
        </li>
        <li>
          <div class="icon"><i class="fas fa-user-edit"></i></div>
          <p class="title">100% Focado<br /> no Revalida</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'About',
  props: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
<template>
  <section class="component_course_home">
  <div id="courses"></div>
    <a name="courses" />
    <h1>Cursos</h1>

    <ul>
      <li>
        <div class="image">
          <img src="@/assets/images/logo_c_strike.png" alt="" border="0" />
        </div>

        <p class="description">
          Curso totalmente focado na <strong>Prova Objetiva</strong> do Revalida!
        </p>

        <a href="https://wa.me/5511932912099" target="_blank" class="global_cta">Inscreva-se agora!</a>
        <router-link :to="{name: 'strikeCourse',}" class="local_cta">Saiba mais</router-link>
      </li>

      <li>
        <div class="image">
          <img src="@/assets/images/logo_c_discursive_master.png" alt="" border="0" />
        </div>

        <p class="description">
          O primeiro curso específico para <strong>Prova Discursiva</strong> do Revalida!
        </p>

        <a href="https://wa.me/5511932912099" target="_blank" class="global_cta">Inscreva-se agora!</a>
        <router-link :to="{name: 'discursiveMasterCourse',}" class="local_cta">Saiba mais</router-link>
      </li>

      <li>
        <div class="image">
          <img src="@/assets/images/logo_c_practicus_intensive.png" alt="" border="0" />
        </div>

        <p class="description">
          Curso e Mentoria exclusiva, focados na <strong>Prova Prática</strong> do Revalida!
        </p>

        <a href="https://wa.me/5511932912099" target="_blank" class="global_cta">Inscreva-se agora!</a>
        <router-link :to="{name: 'practicusIntensiveCourse',}" class="local_cta">Saiba mais</router-link>
      </li>

      <li data-width="half">
        <div class="image">
          <img src="@/assets/images/logo_c_practicus_presencial.png" alt="" border="0" />
        </div>

        <p class="description">
          Mesma didática do curso <strong>PRACTICUS INTENSIVE</strong>, agora no modo <strong>PRESENCIAL!</strong><br /><br />
          Serão <strong>três dias</strong> de treinamento intenso focado na <strong>Prova Prática</strong> do Revalida!
        </p>

        <a href="https://wa.me/5511932912099" target="_blank" class="global_cta">Inscreva-se agora!</a>
        <router-link :to="{name: 'practicusPresencialCourse',}" class="local_cta">Saiba mais</router-link>
      </li>

      <li data-width="half">
        <div class="image">
          <img src="@/assets/images/logo_c_rota_aprovacao.png" alt="" border="0" />
        </div>

        <p class="description">
          O curso mais completo para primeira fase do Revalida
        </p>

        <a href="https://wa.me/5511932912099" target="_blank" class="global_cta">Inscreva-se agora!</a>
        <router-link :to="{name: 'approvalRoute',}" class="local_cta">Saiba mais</router-link>
      </li>

      <!--
      <li data-width="full">
        <div class="image">
          <img src="@/assets/images/logo_c_practicus_presencial.png" alt="" border="0" />
        </div>

        <div class="wrapper">
          <p class="description">
            Mesma didática do curso <strong>PRACTICUS INTENSIVE</strong>, agora no modo <strong>PRESENCIAL!</strong><br />
            Serão <strong>três dias</strong> de treinamento intenso focado na <strong>Prova Prática</strong> do Revalida!
          </p>

          <a href="" class="global_cta">Inscreva-se agora!</a>
          <router-link :to="{name: 'practicusPresencialCourse'}" class="local_cta">Saiba mais</router-link>
        </div>
      </li>

      <li data-width="full">
        <div class="image">
          <ul>
            <li>
              <img src="@/assets/images/logo_c_strike.png" alt="" border="0" />
            </li>
            <li>
              <img src="@/assets/images/logo_c_discursive_master.png" alt="" border="0" />
            </li>
          </ul>
        </div>

        <div class="wrapper">
          <p class="description">
            O combo para aprovação na <strong>primeira fase</strong> do Revalida!
          </p>

          <a href="" class="global_cta">Inscreva-se agora!</a>
          <router-link :to="{name: 'combos'}" class="local_cta">Saiba mais</router-link>      
        </div>
      </li>
      -->
      
    </ul>
  </section>
</template>

<script>
export default {
  name: 'HomeCoursesList',
  props: {
    
  },

  computed: {
    strike2023_1() {
      return `https://pay.herospark.com/strike-2024-1-128376`;
    },

    discursiveMaster2023_1() {
      return `https://pay.herospark.com/discursiva-master-2024-1-128379`;
    },

    practicusIntensive2022_2() {
      return `https://pay.herospark.com/practicus-premium-2023-2-172643`;
    },

    practicusPresencial2022_2() {
      return `https://pay.herospark.com/combo-practicus-premium-presencial-2023-2-172666`;
    },

    approvalRoute() {
      return `https://pay.herospark.com/rota-da-aprovacao-2024-1-128382`
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
<template>
  <main id="content" class="content_combos">
    <div class="alignment">
      
      <div class="logotipo" style="margin-bottom:0;">
        <img src="@/assets/images/logo_c_rota_aprovacao.png" alt="Combos Mundo Revalida" border="0" />
      </div>

      <div class="description richeditor">
        <p>Venha traçar sua Rota em direção à Aprovação!</p>
      </div>

      <!-- <div class="combo_list">
        <div class="title">Primeira fase revalida INEP <strong>2023.1</strong></div>
        <div class="subtitle">
          A junção de dois cursos focados na primeira fase do Revalida<br />
          que vão te fazer gabaritar a <strong>Prova Objetiva</strong> e <strong>Discursiva!</strong>
        </div>
        
        <ul>
          <li>
            <div class="title">Rota da Aprovação <strong>2023.1</strong></div>

            <ul>
              <li>
                <div class="image">
                  <img src="@/assets/images/logo_c_strike.png" alt="" border="0" />
                </div>

                <div class="year">2023.1</div>
                
                <div class="price">
                  <div class="price_por">
                    <span></span>
                    <span>
                      R$
                      <strong>2.998</strong>
                      <span>,00</span>
                    </span>
                  </div>
                </div>
              </li>

              <li>
                <div class="image">
                  <img src="@/assets/images/logo_c_discursive_master.png" alt="" border="0" />
                </div>

                <div class="year">2023.1</div>
                
                <div class="price">
                  <div class="price_por">
                    <span></span>
                    <span>
                      R$
                      <strong>1.998</strong>
                      <span>,00</span>
                    </span>
                  </div>
                </div>
              </li>

              <li>
                <div class="discount">
                  <strong>10%</strong> de desconto
                </div>

                <div class="price">
                  <div class="price_de">
                    <span>de:</span>
                    <span>R$ 4.996,00</span>
                  </div>

                  <div class="price_por">
                    <span>por:</span>
                    <span>
                      R$
                      <strong>4.496</strong>
                      <span>,40</span>
                    </span>
                  </div>
                </div>

                <div class="economy">
                  <span>Economia de:</span>
                  <span>R$ 499,60</span>
                </div>
    
                <a :href="comboStrikeDiscursiveMaster2023_1" target="_blank" class="global_cta">Compre agora</a>
              </li>
            </ul>

            <div class="features" style="text-align:center;">
              <h3 style="margin-top:0;">
                Temos outra novidade que vai te auxiliar na primeira fase,<br />
                não deixe de revisar os principais temas que VÃO estar na sua prova!
              </h3>

              <h2 style="color:#1d5236;font-size:42px !important;">PREMONIÇÕES 1a Fase</h2>
              
              <div class="richeditor" style="width:fit-content;margin:auto;">
                <ul>
                  <li style="margin:10px;">Revisão intensa pré-prova</li>
                  <li style="margin:10px;">Aula 100% on-line</li>
                  <li style="margin:10px;">Disponível até o dia da prova</li>
                  <li style="margin:10px;">APOSTAS Mundo Revalida</li>
                </ul>
              </div>
            </div>

            <div class="features" style="text-align:center;margin-top:35px;">
              <h2>Cronograma:</h2>
    
              <ul>
                <li><i class="far fa-calendar-alt"></i> Data: <span>25/02/2023</span></li>
                <li><i class="far fa-clock"></i> Duração: <span>+ de 10 horas</span></li>
              </ul>

              <div class="price">
                <div class="price_por">
                  <span>por:</span>
                  <span>
                    R$
                    <strong>347</strong>
                    <span>,00</span>
                  </span>
                </div>
              </div>

              <a :href="premonicoes1fase" target="_blank" class="global_cta" style="margin:35px auto 0;">Compre aqui</a>
            </div>
          </li>
        </ul>
      </div> -->

      <div class="combo_list">
        <div class="title">Primeira fase revalida INEP </div>
        <div class="subtitle">
          A junção de dois cursos focados na primeira fase do Revalida<br />
          que vão te fazer gabaritar a <strong>Prova Objetiva</strong> e <strong>Discursiva!</strong>
        </div>
        <ul>
          <li>
            <div class="title" style="font-size:42px;">Rota da Aprovação</div>

            <ul>
              <li>
                <div class="image">
                  <img src="@/assets/images/logo_c_strike.png" alt="" border="0" />
                </div>
  <!--
                <div class="year">2024.1</div>
                -->
                <div class="price">
                <!--
                  <div class="price_por">
                    <span></span>
                    <span>
                      R$
                      <strong>3.448</strong>
                      <span>,00</span>
                    </span>
                  </div>
                  -->
                </div>
              </li>

              <li>
                <div class="image">
                  <img src="@/assets/images/logo_c_discursive_master.png" alt="" border="0" />
                </div>

                
                <!--
                <div class="price">
                  <div class="price_por">
                    <span></span>
                    <span>
                      R$
                      <strong>2.298</strong>
                      <span>,00</span>
                    </span>
                  </div>
                </div>
                -->
              </li>

              <li>
       

                <div class="price">
                <!--
                  <div class="price_de">
                    <span>de:</span>
                    <span>R$ 5.746,00</span>
                  </div>
                  -->
    <!--
                  <div class="price_por">
                    <span>por:</span>
                    <span>
                      R$
                      <strong>5.473</strong>
                      <span>,80</span>
                    </span>
                  </div>
                  -->
                </div>
<!--
                <div class="economy">
                  <span>Economia de:</span>
                  <span>R$ 267,20</span>
                </div>
    -->
                <a href="https://wa.me/5511932912099" target="_blank" class="global_cta">Compre agora</a>
              </li>
            </ul>
<!--
            <div class="features">
              <h2>Cronograma:</h2>

              <ul>
                <li>Acesso ilimitado até o dia da prova primeira fase <strong>Revalida 2024.1</strong></li>

                <li><i class="far fa-calendar-alt"></i> Início: <strong>STRIKE 2024.1</strong> <span>16/10/2023</span></li>
                <li><i class="far fa-clock"></i> Duração: <span>14 semanas</span></li>

                <li><i class="far fa-calendar-alt"></i> Início: <strong>DISCURSIVA MASTER 2024.1</strong> <span>19/10/2023</span></li>
                <li><i class="far fa-clock"></i> Duração: <span>15 semanas</span></li>
              </ul>
            </div>
            -->
          </li>
          <li>
            <div class="features" style="text-align:center;">
              <h3 style="margin-top:0;">
                Temos outra novidade que vai te auxiliar na primeira fase,<br />
                não deixe de revisar os principais temas que VÃO estar na sua prova!
              </h3>

              <h2 style="color:#1d5236;font-size:42px !important;">PREMONIÇÕES 1a Fase</h2>
              
              <div class="richeditor" style="width:fit-content;margin:auto;">
                <ul>
                  <li style="margin:10px;">Revisão intensa pré-prova</li>
                  <li style="margin:10px;">Aula 100% on-line</li>
                  <li style="margin:10px;">Disponível até o dia da prova</li>
                  <li style="margin:10px;">APOSTAS Mundo Revalida</li>
                </ul>
              </div>
            </div>

            <div class="features" style="text-align:center;margin-top:35px;">
         
    
              <ul>
              
                <!-- <li><i class="far fa-clock"></i> Duração: <span>+ de 10 horas</span></li> -->
              </ul>

              <div class="price">
               <!--  <div class="price_de" style="text-decoration: line-through; font-size: 18px;">
                  <span>de:</span>
                  <span>R$ 999,00</span>
                </div> -->

             
              </div>

              <a href="https://wa.me/5511932912099" target="_blank" class="global_cta" style="margin:35px auto 0;">Compre aqui</a>
            </div>
          </li>
        </ul>
      </div>

    </div>
  </main>
</template>

<script>
export default {
    name: 'DiscursiveMaster',

    mounted() {
      this.$nextTick(() => {
        const el = document.querySelector('#topo');
        el && el.scrollIntoView();
      })
    },

    computed: {
      comboStrikeDiscursiveMaster2023_1() {
        return `https://pay.herospark.com/combo-discursiva-master-strike-80396`;
      },

      comboStrikeDiscursiveMaster2023_2() {
        return `https://pay.herospark.com/rota-da-aprovacao-2024-1-128382`;
      },

      premonicoes1fase() {
        // return `https://mundo-revalida.herospark.co/premonicoes-mundo-revalida`;
        return `https://pay.herospark.com/premonicoes-primeira-fase-2024-1-site-193742`;
      }
    },
}
</script>

<style scoped lang="scss">
  .content_combos {
    .alignment {
      & > h1,
      & > h2 {
        text-align: center;
      }
    }

    .combo_list {
      margin: 100px 0 0;
    }
  }
</style>

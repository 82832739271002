<template>
  <main id="content" class="content_course content_discursivamaster">
	  <div class="alignment">
      
      <div class="logotipo">
        <img src="@/assets/images/logo_c_discursive_master.png" alt="Discursiva Master" border="0" />
      </div>

      <div class="description richeditor">
        <p>
          Nós sabemos que a maior dificuldade da primeira fase do <strong>Revalida</strong><br />
          é a <strong>Prova Discursiva</strong>, e é por esta razão que criamos um curso com<br />
          a metodologia que vai revolucionar o seu estudo e te ensinar<br />
          a gabaritar as questões discursivas!
        </p>
      </div>

      <section>
        <h1>Sobre o curso</h1>

        <div class="description richeditor">
          <p>
            O primeiro curso totalmente focado para <strong>Prova Discursiva do Revalida!</strong><br />
            Faremos uma imersão em todos os temas discursivos já cobrados pelo <strong>INEP</strong>, além das <strong>APOSTAS</strong> para cada grande área!
          </p>
        </div>
      </section>

      <section>
        <div class="features">
          <div>
            <h1>Conteúdo</h1>
            <div class="description richeditor">
              <ul>
                <li>+ de 60 aulas;</li>
                <li>Grupo de Whatsapp (BÔNUS);</li>
                <li>Contato direto com os professores (BÔNUS);</li>
                <li>Simulados Discursivos;</li>
                <li>Aula "O Segredo da Prova Discursiva";</li>
                <li>Apostas Mundo Revalida.</li>
              </ul>
            </div>
          </div>

          <div>
            <h1>Módulos</h1>
            <div class="description richeditor">
              <p>
                Serão abordadas todas as grandes áreas:
              </p>
    
              <ul>
                <li>Cirurgia Geral;</li>
                <li>Clínica Médica;</li>
                <li>Ginecologia e Obstetrícia;</li>
                <li>Pediatria;</li>
                <li>Preventiva;</li>
                <li>Apostas prova discursiva.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="course_list">
          <ul>
            <!-- <li>
              <div class="image">
                <img src="@/assets/images/logo_c_discursive_master.png" alt="" border="0" />
              </div>

              <div class="year">2023.1</div>
      
              <div class="features">
                <ul>
                  <li>Acesso ilimitado até o dia da primeira fase <strong>Revalida 2023.1</strong></li>
                  <li><i class="far fa-calendar-alt"></i> Início: <span>21/11/2022</span></li>
                  <li><i class="far fa-clock"></i> Duração: <span>11 semanas</span></li>
                </ul>
              </div>

              <div class="price">
                <div class="price_por">
                  R$
                  <strong>1.998</strong>
                  <span>,00</span>
                </div>
              </div>
      
              <a :href="discursiveMaster2023_1" target="_blank" class="global_cta">Compre aqui</a>
            </li> -->
            
            <li>
              <div class="image">
                <img src="@/assets/images/logo_c_discursive_master.png" alt="" border="0" />
              </div>
<!--
              <div class="year">2024.1</div>
      
              <div class="features">
                <ul>
                  <li>Acesso ilimitado até o dia da prova primeira fase <strong>Revalida 2024.1</strong></li>
                  <li><i class="far fa-calendar-alt"></i> Início: <span>19/10/2023</span><p>(A depender do edital)</p></li>
                  <li><i class="far fa-clock"></i> Duração: <span>15 semanas</span></li>
                </ul>
              </div>

              <div class="price">
                <div class="price_por">
                  R$
                  <strong>2.298</strong>
                  <span>,00</span>
                </div>
              </div>
      -->
              <a href="https://wa.me/5511932912099" target="_blank" class="global_cta">Compre aqui</a>
            </li>
          </ul>
        </div>
      </section>

      <section>
        <h1>Metodologia</h1>

        <div class="method_list">
          <ul>
            <li>
              <h2 class="title">Videoaulas</h2>
              <p class="description">
                Aulas com didática única e mnemônicos exclusivos
              </p>
            </li>
            <li>
              <h2 class="title">Plataforma</h2>
              <p class="description">
                Área restrita do aluno
              </p>
            </li>
            <li>
              <h2 class="title">Simulados</h2>
              <p class="description">
                Simulados Periódicos e simulação do dia da prova
              </p>
            </li>
            <li>
              <h2 class="title">Lives Mundo Revalida</h2>
              <p class="description">
                Lives no Youtube e Instagram focadas na Prova Discursiva
              </p>
            </li>
            <li>
              <h2 class="title">Grupo de Whatsapp </h2>
              <p class="description">
                Contato direto com os professores, esclarecimento de dúvidas e troca de experiências (BÔNUS).
              </p>
            </li>
          </ul>
        </div>
      </section>

      <section>
        <div class="logotipo" style="margin-bottom:0;">
          <img src="@/assets/images/logo_c_rota_aprovacao.png" alt="Combos Mundo Revalida" border="0" />
        </div>

        <router-link :to="{ name: 'approvalRoute', hash: '#topo' }" class="global_cta global_cta_center" style="margin:25px auto 0;">Saiba mais</router-link>
      </section>

      <section>
        <h1>Aula gratuita</h1>

        <div class="description richeditor" style="width:100%;">
          <p>Nossas aulas são repletas de mnemônicos exclusivos para <strong>Prova Discursiva.</strong></p>

          <div class="richeditor_video">
            <figure>
              <div class="global_embed">
                <div class="global_embed_video">
                  <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/I25X8O00B8U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
            </figure>
          </div>
        </div>
      </section>

      <section>
        <section class="component_testimonial">
          <a name="testimonials" />
          <h1>Depoimentos</h1>
          <h2>Venha fazer parte dos milhares de aprovados!</h2>

          <div class="swiper_carousel component_testimonial_carousel">
            <swiper
              :modules="modules"
              :loop='true'
              :slides-per-view='4'
              :pagination="{
                clickable: true,
                dynamicBullets: true,
                dynamicMainBullets: 3
              }"
              :breakpoints="{
                0: {
                  slidesPerView: 1
                },
                1200: {
                  slidesPerView: 4
                }
              }"
            >
              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Gente infelizmente não fiz o simulado pq não terminei os vídeos ainda....
                      Mas vendo o gabarito pude ver q foi show de bola!!!!
                      Vcs são fodas....
                      Vcs estão de parabéns com a atenção, o suporte que dão ao aluno... realmente com o compromisso e comprometimento que vcs têm para com a gnt (aluno)!!!!!
                      Já fiz alguns cursinhos, mas apoio e suporte tão próximo qto vcs eu nunca tinha visto, tenham a certeza de que faz toda a diferença para cada um de nós!
                      Obg pelo apoio nessa fase tão importante de nossas vidas e por fazerem a diferença....
                    </p>

                    <div class="name">Hadailton Amaral Junior</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Gostaria de parabenizar vocês pelo trabalho e dizer que o curso agregou muito para minha prova, que tentei seguir as dicas que também vou levar para a mina vida.
                      Saibam que ganharam uma fã e divulgadora de vocês, fiz outros cursinhos paralelo ao de vocês e tive a certeza que o curso foi um ótimo investimento.
                      Desejo a vocês tudo de bom!!!! E muito sucesso
                    </p>

                    <div class="name">Nataly de Souza D. Daher</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Gente, boa noite! Tava vendo a aula de colangite da @Pia Simone L. Zambrano e eu tô sem palavras. Queria muito agradecer pelas dicas. Quem ainda não viu, veja essa aula pq ela deu dicas valiosas que eu já ia fazer tudo errado na prova.<br /><br />

                      O que é bom a gente compartilha!!
                    </p>

                    <div class="name">Leticia Vaz Fontenele </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Concordo plenamente! O curso é maravilhoso…espero um dia poder conhecer todos os professores! Etapa mto importante das nossas vidas, onde cada um tem diferentes desafios na vida (eu por exemplo com 3 filhinhos pequenos rsrsrs), mas tenho a certeza de que todo o nosso esforço valerá mto à pena! Vamos pq essa prova é nossa
                    </p>

                    <div class="name">Taciana Lopes Campos</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Professor Fabio Nogueira, fazendo eu me apaixonar por Preventiva, um caso de amor e ódio 
                    </p>

                    <div class="name">Thais Costa Cortes </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Realmente ele é nota 10 (Prof. Fabio Nogueira)
                    </p>

                    <div class="name">Leticia Vaz Fontenele</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Excelente professor (Prof. Fabio Nogueira)
                    </p>

                    <div class="name">Elzyane Ferreira da Silva</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Também gosto, o jeito de abordar é top para um tema tão cansativo (Prof. Fabio Nogueira)
                    </p>

                    <div class="name">Zuleimy</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Aiiii, sim ele é demais mesmo (Prof. Fabio Nogueira)
                    </p>

                    <div class="name">Ju</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Ele é sensacional todas as aulas foram maravilhosas!!!<br />
                      Gratidão demais Prof. Fabio Nogueira
                    </p>

                    <div class="name">Tahiza Saraiva</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      As aulas de preventiva são show com o Prof. Fabio Nogueira
                    </p>

                    <div class="name">Lisseth</div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div>
                  <div class="wrapper">
                    <p class="description">
                      Amém Vini, ah sua aula dos segredos foi top…como todas as aulas de todos os professores
                    </p>

                    <div class="name">Taciana Lopes Campos</div>
                  </div>
                </div>
              </swiper-slide>

            </swiper>
          </div>
        </section>
      </section>

    </div>
  </main>
</template>

<script>

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
  name: "DiscursiveMaster",

  components: {
    Swiper,
    SwiperSlide
  },
  
  setup() {
    return {
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },

  mounted() {
    this.$nextTick(() => {
        const el = document.querySelector('#topo');
        el && el.scrollIntoView();
    })
  },

  computed: {
    discursiveMaster2023_1() {
      return `https://pay.herospark.com/discursiva-master-2023-1-80175`;
    },

    discursiveMaster2023_2() {
      return `https://pay.herospark.com/discursiva-master-2024-1-128379`;
    },

    comboStrikeDiscursiveMaster2023_1() {
      return `https://pay.herospark.com/combo-discursiva-master-strike-80396`;
    },

    comboStrikeDiscursiveMaster2023_2() {
      return `https://pay.herospark.com/combo-strike-discursiva-master-2023-2-84360`;
    },
  }
};
</script>

<style scoped lang="scss">
  
</style>
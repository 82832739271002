<template>
  <main id="content" class="content_course content_strike">
	  <div class="alignment">
      
      <div class="logotipo">
        <img src="@/assets/images/logo_c_strike.png" alt="Strike" border="0" />
      </div>

      <div class="description richeditor">
        <p>
          O <strong>STRIKE</strong> é o curso focado na rápida aquisição e consolidação do conhecimento!<br />
          A mesma didática do curso <strong>DISCURSIVA MASTER</strong>, agora aplicada à <strong>Prova Objetiva do Revalida.</strong>
        </p>
      </div>

      <section>
        <h1>Sobre o curso</h1>

        <div class="description richeditor">
          <p>
            Curso semi-extensivo, em que abordaremos todos os principais temas da Prova Objetiva.<br />
            Conteúdo de alta qualidade para te fazer fixar todos os temas importantes para Prova do Revalida.
          </p>
        </div>
      </section>

      <section>
        <div class="features">
          <div>
            <h1>Conteúdo</h1>

            <div class="description richeditor">
              <ul>
                <li>+ de 100 aulas;</li>
                <li>Grupo de Whatsapp (Bônus);</li>
                <li>Contato direto com os professores (Bônus);</li>
                <li>Simulados Periódicos;</li>
                <li>Banco de Questões.</li>
              </ul>
            </div>
          </div>

          <div>
            <h1>Módulos</h1>

            <div class="description richeditor">
              <p>
                Serão <strong>15 semanas</strong> de curso, sendo cada<br />
                semana focada em uma grande área:
              </p>
    
              <ul>
                <li>Cirurgia Geral;</li>
                <li>Clínica Médica;</li>
                <li>Ginecologia e Obstetrícia;</li>
                <li>Pediatria;</li>
                <li>Preventiva;</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="course_list">
          <ul>
            <li>
              <div class="image">
                <img src="@/assets/images/logo_c_strike.png" alt="" border="0" />
              </div>
<!--
              <div class="year">2024.1</div>
      
              <div class="features">
                <ul>
                  <li>Acesso ilimitado até o dia da prova primeira fase <strong>Revalida 2024.1</strong></li>
                  <li><i class="far fa-calendar-alt"></i> Início: <span>16/10/2023</span><p>(A depender do edital)</p></li>
                  <li><i class="far fa-clock"></i> Duração: <span>15 semanas</span></li>
                </ul>
              </div>

              <div class="price">
                <div class="price_por">
                  R$
                  <strong>3.448</strong>
                  <span>,00</span>
                </div>
              </div>
        -->
              <a href="https://wa.me/5511932912099" target="_blank" class="global_cta" style="margin-top:0;">Compre aqui</a>
            </li>

           <!--   <li>
              <div class="image">
                <img src="@/assets/images/logo_c_strike.png" alt="" border="0" />
              </div>

              <div class="year" style="color:#FF252D;">Intensive</div>
              <div class="subtitle" style="font-weight:normal;text-transform:none;">Aulas totalmente focadas na primeira fase!</div>
      
              <div class="features richeditor">
                <ul>
                  <li style="margin-top:10px;">Formato intensivo</li>
                  <li style="margin-top:10px;">Temas importantes</li>
                  <li style="margin-top:10px;">Resumos direcionados</li>
                  <li style="margin-top:10px;">Simulados periódicos</li>
                  <li style="margin-top:10px;">Banco de Questão</li>
                  <li style="margin-top:10px;">Acesso ilimitado até o dia da prova primeira fase 2023.2</li>

                  <li><i class="far fa-calendar-alt"></i> Data: <span>22/05/2023</span></li>
                  <li><i class="far fa-clock"></i> Duração: <span>5 semanas</span><p>Sendo cada semana focado em uma grande área!</p></li>
                </ul>
              </div>

              <div class="price">
                <div class="price_por">
                  R$
                  <strong>1.698</strong>
                  <span>,00</span>
                </div>
              </div>
      
              <a :href="strike2023_intensive" target="_blank" class="global_cta">Compre aqui</a>
            </li> -->
          </ul>
        </div>
      </section>

      <section>
        <h1>Metodologia</h1>

        <div class="method_list">
          <ul>
            <li>
              <h2 class="title">Videoaulas</h2>
              <p class="description">
                Aulas com didática única e mnemônicos exclusivos
              </p>
            </li>
            <li>
              <h2 class="title">Plataforma</h2>
              <p class="description">
                Área restrita do aluno
              </p>
            </li>
            <li>
              <h2 class="title">Simulados</h2>
              <p class="description">
                Simulados Periódicos e Simulação do dia da Prova
              </p>
            </li>
            <li>
              <h2 class="title">Lives Mundo Revalida</h2>
              <p class="description">
                Lives no Youtube e Instagram focadas na Prova Objetiva
              </p>
            </li>
            <li>
              <h2 class="title">Grupo de Whatsapp</h2>
              <p class="description">
                Contato direto com os professores, esclarecimento de dúvidas e troca de experiências (Bônus).
              </p>
            </li>
          </ul>
        </div>
      </section>

      <section>
        <div class="logotipo" style="margin-bottom:0;">
          <img src="@/assets/images/logo_c_rota_aprovacao.png" alt="Combos Mundo Revalida" border="0" />
        </div>
        
        <router-link :to="{ name: 'approvalRoute', hash: '#topo' }" class="global_cta global_cta_center" style="margin:25px auto 0;">Saiba mais</router-link>
      </section>

      <section>
        <h1>Aula gratuita</h1>

        <div class="description richeditor" style="width: 100%">
          <p>Nossas aulas são repletas de mnemônicos exclusivos para <strong>Prova Prática.</strong></p>

          <div class="richeditor_video">
            <figure>
              <div class="global_embed">
                <div class="global_embed_video">
                  <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/a-Emmd2HTIY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
            </figure>
          </div>
        </div>
      </section>

    </div>
  </main>
</template>

<script>

export default {
  name: "Strike",

  mounted() {
    this.$nextTick(() => {
        const el = document.querySelector('#topo');
        el && el.scrollIntoView();
    })
  },

  computed: {
    // strike2023_1() {
    //   return `https://pay.herospark.com/strike-2023-1-80176`;
    // },

    strike2023_2() {
      return `https://pay.herospark.com/strike-2024-1-site-128376`;
    },

    strike2023_intensive() {
      return `https://mundo-revalida.herospark.co/strike-intensive`;
    },

    comboStrikeDiscursiveMaster2023_1() {
      return `https://pay.herospark.com/combo-discursiva-master-strike-80396`;
    },

    comboStrikeDiscursiveMaster2023_2() {
      return `https://pay.herospark.com/combo-strike-discursiva-master-2023-2-84360`;
    },
  }
};
</script>

<style scoped lang="scss">
  
</style>
<template>
	<main id="content" class="content_privacy_policy">
		<div class="alignment">

      <h1>Política de Privacidade</h1>
    <body>

<h1>Política de Privacidade da Mundo Revalida</h1>

<p>A <strong>Mundo Revalida</strong> valoriza a privacidade e a segurança das informações de seus usuários. Esta Política de Privacidade descreve como coletamos, usamos, armazenamos e protegemos os dados pessoais dos alunos e visitantes do nosso site e sistema de banco de dados.</p>

<hr>
<br>
<h2>1. Coleta de Informações</h2>

<p>Coletamos informações pessoais fornecidas voluntariamente pelos usuários ao se cadastrarem ou utilizarem nossos serviços, incluindo:</p>

<ul>
    <li>Nome completo;</li>
    <li>CPF;</li>
    <li>Endereço de e-mail;</li>
    <li>Número de telefone;</li>
    <li>Endereço residencial;</li>
    <li>Informações acadêmicas e profissionais.</li>
</ul>

<hr>
<br>
<h2>2. Uso das Informações</h2>

<p>Utilizamos os dados coletados para as seguintes finalidades:</p>

<ul>
    <li>Fornecer e personalizar os cursos e treinamentos oferecidos;</li>
    <li>Gerenciar o acesso ao sistema de banco de dados dos alunos;</li>
    <li>Comunicar sobre atualizações, novidades e promoções;</li>
    <li>Melhorar nossos serviços e a experiência do usuário;</li>
    <li>Cumprir obrigações legais e regulatórias.</li>
</ul>

<hr>
<br>
<h2>3. Compartilhamento de Informações</h2>

<p>A Mundo Revalida não compartilha informações pessoais com terceiros, exceto:</p>

<ul>
    <li>Quando necessário para o funcionamento dos serviços, com parceiros que seguem os mesmos padrões de privacidade;</li>
    <li>Para cumprir exigências legais ou ordens judiciais;</li>
    <li>Para proteger os direitos, propriedade ou segurança da empresa e de seus usuários.</li>
</ul>

<hr>
<br>
<h2>4. Segurança das Informações</h2>

<p>Adotamos medidas de segurança adequadas para proteger os dados pessoais contra acesso não autorizado, alteração, divulgação ou destruição. Nossos sistemas são monitorados e atualizados regularmente para garantir a integridade das informações.</p>

<hr>
<br>
<h2>5. Retenção de Dados</h2>

<p>Os dados pessoais são mantidos pelo tempo necessário para cumprir as finalidades para as quais foram coletados ou conforme exigido por lei. Após esse período, as informações são excluídas de forma segura.</p>

<hr>
<br>
<h2>6. Direitos dos Usuários</h2>

<p>Os usuários têm os seguintes direitos em relação aos seus dados pessoais:</p>

<ul>
    <li>Acessar e obter uma cópia de seus dados;</li>
    <li>Solicitar a correção de informações incompletas ou desatualizadas;</li>
    <li>Solicitar a exclusão de dados, nos termos da lei;</li>
    <li>Opor-se ao processamento de dados em determinadas circunstâncias;</li>
    <li>Retirar o consentimento para o processamento de dados, quando aplicável.</li>
</ul>

<p>Para exercer esses direitos, entre em contato através dos canais fornecidos nesta política.</p>

<hr>
<br>
<h2>7. Cookies e Tecnologias Semelhantes</h2>

<p>Utilizamos cookies e tecnologias semelhantes para aprimorar a funcionalidade do nosso site e oferecer uma experiência personalizada. Os usuários podem gerenciar as preferências de cookies nas configurações do navegador.</p>

<hr>
<br>
<h2>8. Links para Sites de Terceiros</h2>

<p>Nosso site pode conter links para sites de terceiros. Não nos responsabilizamos pelas práticas de privacidade desses sites e recomendamos que os usuários leiam as políticas de privacidade correspondentes.</p>

<hr>
<br>
<h2>9. Alterações na Política de Privacidade</h2>

<p>Esta Política de Privacidade pode ser atualizada periodicamente para refletir mudanças em nossos serviços ou em requisitos legais. Recomendamos que os usuários revisem esta página regularmente para se manterem informados.</p>

<hr>
<br>
<h2>10. Contato</h2>

<p>Em caso de dúvidas, solicitações ou reclamações relacionadas a esta Política de Privacidade, entre em contato conosco:</p>

<ul>
    <li><strong>E-mail:</strong> <a href="mailto:educacao@mundorevalida.com.br">educacao@mundorevalida.com.br</a></li>
    <li><strong>Endereço:</strong> Rua Exemplo, 123, Bairro, Cidade, Estado, CEP</li>
</ul>

<hr>
<br>
<h2>11. Lei Aplicável</h2>

<p>Esta Política de Privacidade é regida e interpretada de acordo com as leis da República Federativa do Brasil, especialmente a Lei nº 13.709/2018 (Lei Geral de Proteção de Dados Pessoais - LGPD).</p>

<hr>
<br>
<h2>12. Consentimento</h2>

<p>Ao utilizar nossos serviços, o usuário concorda com os termos desta Política de Privacidade e autoriza o tratamento de seus dados pessoais conforme descrito.</p>

</body>
  
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'PrivacyPolicyView',
  components: {
    
  },

  mounted() {
    
  }
}
</script>

<style scoped lang="scss">

</style>
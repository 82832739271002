<template>
  <main id="content" class="content_course content_practicuspresencial">
	  <div class="alignment">
      
      <div class="logotipo">
        <img src="@/assets/images/logo_c_practicus_presencial.png" alt="Practicus Presencial" border="0" />
      </div>

      <div class="description richeditor">
        <p>
          Com a mesma didática do <strong>PRACTICUS INTENSIVE</strong> e com nosso conhecimento<br />
          em recursos para <strong>Prova Prática</strong> criamos o curso <strong>PRACTICUS PRESENCIAL!</strong>
        </p>
      </div>

      <section>
        <h1>Sobre o curso</h1>

        <div class="description richeditor">
          <p>
            O <strong>PRACTICUS PRESENCIAL</strong> é um treinamento presencial focado na prova prática do <strong>Revalida.</strong>
          </p>

          <p>
            Serão três dias inteiros de treinamento guiado por nossos professores onde o objetivo é te ensinar<br />
            de maneira didática e sistematizar os checklists das provas práticas.
          </p>

          <p>
            Nosso foco é que vocês vivenciem o máximo de temas para <strong>Prova Prática</strong>, a fim de alcançar<br />
            a excelência e aprovação!
          </p>
        </div>
      </section>

      <section>
        <div class="logotipo">
          <img src="@/assets/images/logo_c_practicus_presencial.png" alt="Practicus Presencial" border="0" />
<!--
          <div class="year">2023.2</div>
          <div class="date"><i class="far fa-calendar-alt"></i> 18, 19 e 20 de novembro</div>
          -->
        </div>
      </section>

      <section>
        <h1>Programação</h1>
        <br /><br />

        <h2 class="subtitle_highlight">Sábado e Domingo</h2>
        <h2>Conteúdo:</h2>
        <div class="description richeditor">
          <ul>
            <li>Palestra inicial Destrinchando o Checklist</li>
            <li>Kit de boas-vindas Mundo Revalida</li>
            <li>Treinamento de estações práticas em pequenos grupos</li>
            <li>Contato direto com os professores</li>
            <li>Coffe Break incluso</li>
            <li>Almoço incluso</li>
            <li>Coletânea Checklist Mundo Revalida</li>
            <li>Resumos direcionados</li>
          </ul>
        </div>
        <br /><br />

        <h2 class="subtitle_highlight">Segunda-feira</h2>
        <h2>Conteúdo:</h2>
        <div class="description richeditor">
          <ul>
            <li>Simulação real do dia da prova</li>
            <li>Coletânea Checklist: Simulado Mundo Revalida</li>
            <li>Resumos direcionados</li>
            <li>Encerramento: "O Segredo da Prova Prática"</li>
          </ul>
        </div>
      </section>

      <section>
        <div class="combo_list" style="margin-bottom: 5rem">
          <ul>
            <li>
              <ul>
                <li>
                  <div class="image">
                    <img src="@/assets/images/logo_c_practicus_presencial.png" alt="" border="0" />
                  </div>
                <!--
                  <div class="year">2023.2</div>
                  -->
                </li>

                <!-- <li>
                  <div class="image">
                    <img src="@/assets/images/logo_c_practicus_intensive.png" alt="" border="0" />
                  </div>

                  <div class="year">2022.2</div>
                  
                  <div class="price">
                    <div class="price_por">
                      <span></span>
                      <span>
                        R$
                        <strong>2.998</strong>
                        <span>,00</span>
                      </span>
                    </div>
                  </div>
                </li> -->

                <li>
                <!-- <div class="discount">
                  <strong>20%</strong> de desconto
                </div> -->
<!--
                <div class="price">
                  <div class="price_por">
                    <span>por:</span>
                    <span>
                      R$
                      <strong>R$4.240</strong>
                      <span>,00</span>
                    </span>
                  </div>
                </div>
-->
                <!-- <div class="economy">
                  <span>Economia de:</span>
                  <span>R$ 2.998,00</span>
                </div> -->
    
                <a href="https://wa.me/5511932912099" target="_blank" class="global_cta">Compre agora</a>
              </li>
              </ul>
            </li>
          </ul>
        </div>
        <h1>Combo Practicus</h1>
        <!-- <h2>
          Ao comprar o curso <strong>PRACTICUS PRESENCIAL</strong> você ganha<br />
          gratuitamente o curso <strong>PRACTICUS INTENSIVE (100% online).</strong>
        </h2> -->

        <div class="combo_list">
          <ul>            
            <!-- <li>
              <ul>
                <li>
                  <div class="image">
                    <img src="@/assets/images/logo_c_practicus_presencial.png" alt="" border="0" />
                  </div>

                  <div class="year">2023.1</div>
                  
                  <div class="price">
                    <div class="price_por">
                      <span></span>
                      <span>
                        R$
                        <strong>4.000</strong>
                        <span>,00</span>
                      </span>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="image">
                    <img src="@/assets/images/logo_c_practicus_intensive.png" alt="" border="0" />
                  </div>

                  <div class="year">ONLINE 2023.1</div>
                  
                  <div class="price">
                    <div class="price_por">
                      <span></span>
                      <span>
                        R$
                        <strong>2.998</strong>
                        <span>,00</span>
                      </span>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="price">
                    <div class="price_de">
                      <span>de:</span>
                      <span>R$ 6.998,00</span>
                    </div>
                    
                    <div class="price_por">
                      <span>por:</span>
                      <span>
                        R$
                        <strong>4.998</strong>
                        <span>,00</span>
                      </span>
                    </div>
                  </div>
      
                  <a :href="practicusIntensive2023_1" target="_blank" class="global_cta">Compre agora</a>
                </li>
              </ul>
            </li> -->

            <li>
              <ul>
                <li>
                  <div class="image">
                    <img src="@/assets/images/logo_c_practicus_presencial.png" alt="" border="0" />
                  </div>
<!--
                  <div class="year">2023.2</div>
                  -->
                  <!--
                  <div class="price">
                    <div class="price_por">
                      <span></span>
                      <span>
                        R$
                        <strong>4.240</strong>
                        <span>,00</span>
                      </span>
                    </div>
                  </div>
                  -->
                </li>

                <li>
                  <div class="image">
                    <img src="@/assets/images/logo_c_practicus_intensive.png" alt="" border="0" />  
                  </div>
            <!--
                  <div class="year">PREMIUM 2023.2</div>
                  -->
                  <!--
                  <div class="price">
                    <div class="price_por">
                      <span></span>
                      <span>
                        R$
                        <strong>4.238</strong>
                        <span>,00</span>
                      </span>
                    </div>
                  </div>
                  -->
                </li>

                <li>
                
                  <div class="price">
                  <!--
                    <div class="price_de">
                      <span>de:</span>
                      <span>R$8.478,00</span>
                    </div>
                  -->
<!--
                    <div class="price_por">
                      <span>por:</span>
                      <span>
                        R$
                        <strong>6.358</strong>
                        <span>,00</span>
                      </span>
                    </div>
                     -->
                  </div>
     
                  <a href="https://wa.me/5511932912099" target="_blank" class="global_cta">Compre agora</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </section>

    </div>
  </main>  
</template>

<script>
export default {
  name: "DiscursiveMaster",

  mounted() {
    this.$nextTick(() => {
        const el = document.querySelector('#topo');
        el && el.scrollIntoView();
    })
  },

  computed: {
    practicusPresencial2022_2() {
      return `https://pay.herospark.com/practicus-presencial-2023-2-172650`;
    },

    // practicusIntensive2023_1() {
    //   return `https://wa.me/5511914297094`;
    // },

    practicusIntensive2023_2() {
      return `https://pay.herospark.com/combo-practicus-premium-presencial-2023-2-172666`;
    },
  }
};
</script>

<style scoped lang="scss">
  .subtitle_highlight {
    color: #000;
    font-weight: bold !important;
    width: fit-content;
    margin: 25px auto 0;
    background: #bf9213;
  }
</style>
